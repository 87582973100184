<template lang="pug">
  //- fill scroll area
  .hidden.lg_block.absolute.overlay.pointer-events-none
    //- sticky layer
    .sticky.top-0.right-0.w-1x2.h-screen.ml-auto.flex.px-20.py-32.bg-white.transition-opacity.duration-700(:class="{'opacity-0': !visible, 'pointer-events-auto': visible}")
      //- fill padded frame
      .relative.w-full.cursor-pointer(@click="$emit('mediaclick', slice.primary.media.url)")
        resp-img(:image="slice.primary.media", :bg="true", fit="object-contain object-center", :lazy="true")
      //- (caption)
      figcaption.hidden.lg_block.absolute.bottom-0.left-0.w-full.px-20.pb-6.text-center.font-louize.text-sm
        rich-text.w-full.text-center(:field="slice.primary.caption")

</template>

<script>
export default {
  name: 'SliceEssayImage--Fixed',
  props: {
    slice: { type: Object, default: () => ({ primary: {} }) }
  },
  data () {
    return {
      waypointEl: null,
      observer: null,
      visible: false
    }
  },
  methods: {
    insertWaypoint () {
      // add waypoint to dom, before slice position
      this.waypointEl = document.createElement('div')
      let css = 'h-px transform -translate-y-24'
      // debug css
      if (this.$route.hash === '#debug') {
        css += ' opacity-50 text-red border-b border-current'
        this.waypointEl.innerText = 'image'
      }
      this.waypointEl.setAttribute('class', css)
      // insert
      this.$el.insertAdjacentElement('beforeBegin', this.waypointEl)

      // observe waypoint visiblity
      this.observer = new IntersectionObserver(entries => {
        // if in/out of top of screen...
        if (entries[0].boundingClientRect.top < 200) {
          // image visibility is opposite of waypoint visiblity
          this.visible = !entries[0].isIntersecting
        }
      })
      this.observer.observe(this.waypointEl)
    }
  },
  mounted () {
    this.insertWaypoint()
  },
  destroyed () {
    this.waypointEl.remove()
  }
}
</script>

<style>
#essay:active{
  & .slice-essay-image-fixed{
    pointer-events: auto;
  }
}
</style>
