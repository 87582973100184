<template lang="pug">
  div(@click="$emit('next')", style="cursor:none", :class="{'pointer-events-none': !text}")
    //- (right-arrow)
    //- app-cursor
      svg-long-arrow.block(:class="{'opacity-0': text}")
    //- (text ?)
    app-cursor(:class="{'opacity-0ff': !text}")
      .font-louize.text-xl.tracking-wide.whitespace-no-wrap.w-4.flex.justify-center(:style="{color: slice.primary.cursor_text_color}")
        | {{ text }}
</template>

<script>
export default {
  name: 'CursorText',
  props: {
    slice: { type: Object, default: () => ({ primary: { cursor_text: [] } }) }
  },
  computed: {
    text () {
      const txt = this.slice.primary.cursor_text || ''
      return txt.length ? this.$prismic.asText(txt) : false
    }
  }
}
</script>

<style>
</style>
