<template lang="pug">
  //- position set on parent
  .essay-overlay.flex(:class="{'bg-vanilla': scheme === '1', 'bg-white': scheme === '2'}")
    button.absolute.z-20.top-0.right-0.p-10.lg_p-20(aria-label="Close", @click="close")
      svg-x.block.h-8.w-8.lg_h-12.lg_w-12
    article.flex.w-full(v-if="doc")
      //- scrolling layer
      section#essay.w-full.overflow-y-scroll
        .max-w-essay.mx-auto.p-8.md_p-20.lg_pb-96.relative(:class="{'lg_px-0 lg_pt-20': scheme === '2'}")
          slice-body(:slices="doc.data.body", :templates="templates", :scheme="scheme", @mediaclick="onMediaClick")
      //- lightbox
      essay-slideshow.z-10(ref="lightbox", :slices="doc.data.slides", :visible="lbox", @close="lbox = false")
      //- (lightbox button mbl)
      button.absolute.bottom-0.left-0.px-8.py-6.text-red.text-base.lg_hidden(v-if="doc.data.slides.length > 0", @click="openLightbox") Visual Essay
</template>

<script>
import SliceEssayText from '@/slices/SliceEssayText'
import SliceEssayImage from '@/slices/SliceEssayImage'
import SliceEssayQuote from '@/slices/SliceEssayQuote'
export default {
  name: 'EssayOverlay',
  props: {
    doc: { type: Object, default: undefined },
    isActive: { type: Boolean, default: false }
  },
  data () {
    return {
      templates: {
        text: SliceEssayText,
        image: SliceEssayImage,
        essay_quote: SliceEssayQuote
      },
      lbox: null
    }
  },
  computed: {
    scheme () {
      return this.doc?.data.format?.slice(-1)
    }
  },
  methods: {
    close () {
      if (this.lbox) {
        this.lbox = false
        return
      }
      this.$emit('closeEssay')
    },
    onMediaClick (url) {
      this.lbox = true
      this.$refs.lightbox.jumpTo({ url: url })
    },
    openLightbox () {
      this.lbox = true
      this.$refs.lightbox.jumpTo({ index: 0 })
    }
  },
  mounted () {
    this.$store.commit('setUIColor', 'black')
  },
  destroyed () {
    this.$store.commit('setUIColor')
  }
}
</script>

<style>
.parallax{
  perspective: -1px;
  /*height:100%; handled by flex*/
}
.parallax__layer--back {
  transform: translateZ(2px);
  /*transform-origin:center center;*/
}
</style>
