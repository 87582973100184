import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/listen/:playlist',
    name: 'playlist',
    component: () => import(/* webpackChunkName: "playlist" */ '../views/Playlist.vue')
  },
  {
    path: '/info/:info',
    name: 'infos',
    component: () => import(/* webpackChunkName: "info" */ '../views/Infos.vue'),
    meta: { key: 'info' },
    children: [
      {
        path: '',
        name: 'info',
        meta: { key: 'info' },
        component: () => import(/* webpackChunkName: "info" */ '../views/Info.vue')
      }
    ]
  },
  {
    path: '/olfactory',
    name: 'olfactory',
    component: () => import(/* webpackChunkName: "olfactory" */ '../views/Olfactory.vue')
  },
  {
    path: '/objects',
    name: 'objects',
    component: () => import(/* webpackChunkName: "objects" */ '../views/Objects.vue')
  },
  {
    path: '/films',
    name: 'film',
    component: () => import(/* webpackChunkName: "film" */ '../views/Film.vue')
  },
  {
    path: '/writing',
    name: 'essays',
    component: () => import(/* webpackChunkName: "essays" */ '../views/Essays.vue'),
    children: [
      {
        path: ':essay',
        name: 'essay',
        component: () => import(/* webpackChunkName: "essays" */ '../views/Essay.vue')
      }
    ]
  },
  // passages (The Garden, Safe Passage)
  {
    path: '/:passage/:slide?',
    component: () => import(/* webpackChunkName: "passage" */ '../views/Passages.vue'),
    meta: { key: 'passage' },
    children: [
      {
        path: '',
        name: 'passage',
        meta: { key: 'passage' },
        component: () => import(/* webpackChunkName: "passage" */ '../views/Passage.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // disable browser handling saved position
    if ('scrollRestoration' in history) history.scrollRestoration = 'manual'
    // passage > passage: no scroll
    if (to.name === from.name && to.name === 'passage') return
    // wait for the route leave transition to end
    return new Promise(resolve => {
      this.app.$root.$once('afterRouteLeave', () => {
        const position = savedPosition || { x: 0, y: 0 }
        resolve(position)
      })
    })
  }
})

export default router
