<template lang="pug">
  .slice-essay-quote(:class="classes")
    //- lines...
    rich-text(v-for="(item, i) in slice.items", :field="item.text", :class="{'text-right': item.right_align && scheme === '1'}", :key="i + $prismic.asText(item.text)")
</template>

<script>
export default {
  name: 'SliceEssayQuote',
  props: {
    slice: { type: Object, default: () => ({ items: [] }) },
    scheme: { type: String, default: '1' }
  },
  computed: {
    classes () {
      return {
        // essay type 1 (very large)
        'font-louize text-2xl my-2lh-tight md_text-6xl lg_text-8xl leading-tight lg_w-7x12 lg_my-lh-tight bold-is-red': this.scheme === '1',
        // essay type 2 (red nils)
        'font-nils text-xl text-red text-center px-8 lg_px-20 md_w-1x2 my-3lh-snug': this.scheme === '2'
      }
    }
  }
}
</script>

<style>
</style>
