<template lang="pug">
  .hero-linn-text.font-linn.leading-normal.tracking-widest.max-w-artboard.uppercase.py-12.pl-8.pr-4.md_p-20.md_text-2xl.lg_pt-20.lg_pl-36.lg_pb-24.lg_pr-72.text-lg.lg_text-4xl.lg_leading-tight
    slot
</template>

<script>
export default {
  name: 'AnimatingLinnText'
}
</script>

<style>
.hero-linn-text{
  & p:nth-child(n + 2) {
    text-indent: 2.6em
  }
}
</style>
