<template lang="pug">
  .slice-slide-quote.absolute.overlay.text-vanilla(:style="{background: slice.primary.bg_color, color: slice.primary.text_color}")
    .absolute.overlay.text-xl.lg_text-4xl.flex.items-center.justify-center.px-8.lg_px-48(:style="{height: winH + 'px'}")
      .text-center
        span.transition-opacity.duration-700(v-for="(item, i) in slice.items", :class="{'opacity-0': i > current}") {{ item.text }}&nbsp;
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'SliceSlideQuote',
  props: {
    slice: { type: Object, default: () => ({ primary: {} }) },
    isActive: { type: Boolean, default: false }
  },
  data () {
    return {
      current: -1,
      speed: 150,
      animation: null
    }
  },
  computed: {
    ...mapState(['winH'])
  },
  methods: {
    animateInText () {
      if (!this.isActive) return
      this.animation = setTimeout(() => {
        if (this.current <= this.slice.items.length) {
          this.current++
          return this.animateInText()
        }
        // end? skip to next !
        setTimeout(() => this.$emit('next'), 1200)
      }, this.speed)
    },
    runAnimationOnce () {
      if (this.current > -1 || !this.isActive) return
      this.animateInText()
    }
    // reset () {
    //   clearTimeout(this.animation)
    //   this.current = -1
    // }
  },
  watch: {
    isActive (active) {
      setTimeout(() => this.runAnimationOnce(), 1000)
    }
  },
  mounted () {
    setTimeout(() => this.runAnimationOnce(), 1000)
  }
}
</script>

<style>
</style>
