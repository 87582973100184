<template lang="pug">
  router-link(:to="to || linkResolver(field)", :_target="field.target")
    slot
</template>

<script>
import linkResolver from '@/plugins/prismic/link-resolver'
export default {
  name: 'CMSLink',
  props: {
    to: { type: Object, String, default: undefined },
    field: { type: Object, default: () => ({}) }
  },
  methods: {
    linkResolver
  }
}
</script>

<style>
</style>
