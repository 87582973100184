<template lang="pug">
  section.slice-body
    component(v-for="(slice, i) in slices", v-if="has(slice)", :is="templates[slice.slice_type]", :slice="slice", :index="i", v-bind="$attrs", v-on="$listeners", :key="`slice${i}`")
</template>

<script>
// import SliceBlurImageset from '@/slices/SliceBlurImageset'
// import SliceImageFull from '@/slices/SliceImageFull'
// import SliceImageDiptych from '@/slices/SliceImageDiptych'
export default {
  name: 'SliceBody',
  props: {
    slices: { type: Array, default: () => ([]) },
    templates: { type: Object, default: () => ({}) }
  },
  methods: {
    has (slice) {
      return this.templates[slice.slice_type]
      // return this.$options.components['slice--' + slice.slice_type]
    }
  },
  components: {
    // 'slice--blur_imageset': SliceBlurImageset,
    // 'slice--image_full': SliceImageFull,
    // 'slice--image_diptych': SliceImageDiptych
  }
}
</script>

<style>
</style>
