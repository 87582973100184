<template lang="pug">
  nav.passages-thumbnails.flex.flex-wrap.items-center.p-5.md_p-0(role="navigation")
    template(v-for="(slice, i) in slicesAll")
      .block.p-5.md_p-20.lg_mb-20.text-center(:class="[width(slice)]", :key="slice._passage + slice._slide", :data-type="slice.slice_type")
        router-link.block.overflow-hidden.group(:to="{name: 'passage', params: {passage: slice._passage, slide: slice._slide }}")
          //- essay
          template(v-if="slice.slice_type === 'essay'")
            .inline-block.p-16.text-left.font-messina.text-xl.text-navy(v-if="slice._doc")
              rich-text(:field="slice._doc.data.title")
              rich-text(:field="slice._doc.data.lede")
          //- image slide
          template(v-else)
            resp-img.w-full.transform.group-hover_scale-103.duration-500(v-if="image(slice)", :image="image(slice)", :lazy="true")
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'PassagesThumbnails',
  computed: {
    ...mapState(['docs']),
    ...mapGetters(['passages']),
    slicesAll () {
      /*
       * Collect all slices, with some exclusions
      */
      const slices = []
      const excludeTypes = ['title_slide_interactive', 'quote']
      // sort by active passage
      const passages = this.passages.slice().sort((a, b) => a.uid === this.$route.params.passage ? -1 : 0)
      // loop through slices from all passages...
      passages.forEach(doc => doc.data.body.forEach((slice, i) => {
        if (!excludeTypes.includes(slice.slice_type)) {
          slices.push({ _passage: doc.uid, _slide: i, ...slice })
        }
      }))
      // map-in "moving image" docs...
      slices.map(slice => {
        // add moving image doc
        if (slice.primary?.media?.type === 'moving_image') {
          slice._media = this.docs.find(doc => doc.id === slice.primary.media.id)
        }
        // add essay doc
        // if (slice.slice_type === 'essay') {
        //   slice._doc = this.docs.find(doc => doc.id === slice.primary.doc.id)
        // }
        return slice
      })
      // finish !
      return slices
    }
  },
  methods: {
    width (slice) {
      // TODO - handle portrait "moving image"/video edge case?
      const image = this.image(slice)
      const isPrtImg = image?.height > image?.width
      return isPrtImg ? 'w-1x3 lg_w-2x7'
        : 'w-1x2 lg_w-3x7'
    },
    image (slice) {
      // video poster or slide image
      const img = slice._media?.data?.poster ?? slice.primary.media
      return img?.url ? img : null
    }
  }
}
</script>

<style>
/*@media (hover:hover) {
  .passages-thumbnails {
    & a .resp-img{
      transition: transform 500ms;
    }
    & a:hover .resp-img{
      transform:scale(1.04,1.04);
    }
  }
}*/
</style>
