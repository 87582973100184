<template lang="pug">
  .slice-slide-essay.absolute.overlay.flex.flex-col.justify-between.p-20(v-if="doc", :class="{'bg-white': doc.data.format === 'Scheme 2'}")
    rich-text.font-louize.text-5xl(:field="doc.data.title")
    rich-text.font-louize.text-4xl(:field="doc.data.lede")
    //- article as overlay
    transition(name="essayoverlay")
      essay-overlay.fixed.overlay.z-40(v-if="overlayVisible", :doc="doc", @closeEssay="closeEssay")
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'SliceSlideEssay',
  props: {
    slice: { type: Object, default: () => ({ primary: {} }) },
    isActive: { type: Boolean, default: false } // slice is current slice in slideshow
  },
  data () {
    return {
      overlayVisible: false
    }
  },
  computed: {
    doc () {
      const id = this.slice.primary.doc?.id
      return id && this.$store.state.docs.find(doc => doc.id === id)
    }
  },
  methods: {
    ...mapMutations(['lockScroll', 'unlockScroll']),
    afterScrollToTop (callback) {
      if (window.pageYOffset === 0) return callback()
      let wait
      const onScroll = () => {
        clearTimeout(wait)
        // after scroll ends...
        wait = setTimeout(() => {
          // if at the top...
          if (window.pageYOffset === 0) {
            callback()
            window.removeEventListener('scroll', onScroll) // unbind
          // ...else: scroll to top!
          } else {
            this.$scrollTo(this.$root.$el, { cancelable: false })
          }
        }, 100)
      }
      // bind
      window.addEventListener('scroll', onScroll)
    },
    revealEssay () {
      if (!this.doc) return
      this.afterScrollToTop(() => {
        this.lockScroll()
        this.overlayVisible = true
      })
    },
    closeEssay () {
      this.unlockScroll()
      this.$emit('next')
    }
  },
  watch: {
    isActive (active) {
      // active...
      if (active) {
        this.revealEssay()
        this.$store.commit('setUIColor', 'black')
      // inactive...
      } else {
        this.$store.commit('setUIColor')
      }
    },
    doc () {
      // if doc is late...
      if (this.isActive) this.revealEssay()
    }
  },
  mounted () {
    // if page is loaded on this slide then update on @mounted so @after-enter fires...
    if (this.isActive) setTimeout(() => this.revealEssay(), 1000)
  }
}
</script>

<style>
.essayoverlay-enter-active{
  transition: opacity 1200ms 1200ms;
}
.essayoverlay-leave-active{
  transition: opacity 1200ms;
}
.essayoverlay-leave-to,
.essayoverlay-enter{
  opacity:0;
}
</style>
