<template lang="pug">
  .slice-essay-image-parallax.relative.w-full.my-16.lg_my-0
    //- (debug line)
    .block.h-0.opacity-50.text-red.border-b.border-red(v-if="$route.hash === '#debug'") image
    //- image
    figure.w-full.lg_absolute.lg_w-5x12.top-0.right-0.lg_pl-32.cursor-pointer(ref="frame")
      resp-img.w-full.lg_transform(:image="slice.primary.media", :style="{'--transform-translate-y': dY + 'px'}", :lazy="true", @click.native="$emit('mediaclick', slice.primary.media.url)")
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'SliceEssayImage--Parallax',
  props: {
    slice: { type: Object, default: () => ({ primary: {} }) }
  },
  data () {
    return {
      body: null,
      dY: 0
    }
  },
  computed: {
    ...mapState(['winH'])
  },
  methods: {
    bindScroll () {
      this.body = this.body || document.getElementById('essay')
      return this.body?.addEventListener('scroll', this.onScroll)
    },
    unbindScroll () {
      return this.body?.removeEventListener('scroll', this.onScroll)
    },
    onScroll () {
      // if two column layout...
      if (this.$store.getters.is('lg')) {
        const box = this.$refs.frame.getBoundingClientRect()
        const shift = box.top - this.winH
        const onscreen = box.bottom - box.height * 0.25 > 0
        // if in viewport...
        if (shift < 0 && onscreen) {
          // animate parallax
          requestAnimationFrame(() => {
            // shift upward with a little downward offset...
            this.dY = shift * 0.4 + box.height * 0.5
          })
        }
      }
    }
  },
  mounted () {
    this.onScroll() // set initial position while loading
    this.bindScroll()
  },
  destroyed () {
    this.unbindScroll()
  }
}
</script>

<style>
</style>
