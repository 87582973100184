<template lang="pug">
  .slice-garden-title-slide.absolute.overlay
    //- image(s)
    resp-img.transition-opacity.duration-700(v-for="(item, i) in slice.items", :bg="true", :image="item.image", :class="{'opacity-0': i !== current}", :key="'i' + i")
    //- h set in px for ios centering...
    .absolute.overlay.flex.items-center.justify-center(:style="{height: winH + 'px'}")
      //- title
      h2.relative.z-10.font-nils.sr-hide.text-4xl.lg_text-6xl.text-navy.transition-opacity.duration-500.whitespace-no-wrap(:style="{color: slice.primary.text_color, opacity: textOpacity }") {{ $prismic.asText(slice.primary.heading) }}
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'SliceTitleCard',
  props: {
    slice: { type: Object, default: () => ({ primary: {}, items: [] }) },
    hoverItem: { type: Number, default: 0 },
    isActive: { type: Boolean, default: false }
  },
  data () {
    return {
      current: 0,
      timer: null,
      interval: 700
    }
  },
  computed: {
    ...mapState(['canHover', 'winH']),
    textOpacity () {
      // fade out text as you progress over items (if multiple)
      return this.slice.items.length <= 1 ? 1
        : 1 - this.hoverItem / (this.slice.items.length - 1)
    }
  },
  methods: {
    autoplayImages () {
      // cancel?
      if (!this.isActive || this.canHover) {
        return clearTimeout(this.timer)
      }
      // recursive animation
      this.timer = setTimeout(() => {
        if (this.current + 1 < this.slice.items.length) {
          this.current++
          this.autoplayImages()
        }
      }, this.interval)
    }
    // uiColor () {
    //   this.$store.commit('setUIColor', 'white')
    // }
  },
  watch: {
    hoverItem () {
      this.current = this.hoverItem
    },
    isActive () {
      setTimeout(() => this.autoplayImages(), 1000)
      // this.uiColor()
    }
  },
  mounted () {
    setTimeout(() => this.autoplayImages(), 1000)
    // this.uiColor()
  }
}
</script>

<style>
</style>
