<template lang="pug">
  .slice-essay-text.font-louize
    rich-text(:field="slice.primary.text", :class="classes")
</template>

<script>
export default {
  name: 'SliceEssayText',
  props: {
    slice: { type: Object, default: () => ({ primary: {} }) },
    scheme: { type: String, default: '1' }
  },
  computed: {
    classes () {
      let clss
      switch (this.scheme) {
        case '1': {
          const lgFz = this.slice.slice_label === 'small' ? 'lg_text-34' : 'lg_text-42'
          clss = `text-mdd md_text-3xl ${lgFz} leading-tight children-mb-lh-tight lg_w-7x12 bold-is-red`
          break
        }
        case '2':
          clss = 'text-mdd md_text-lgg lg_text-xl leading-snug children-mb-lh-snug lg_w-1x2 lg_px-20'
          break
      }
      return clss
    }
  }
}
</script>

<style>
</style>
