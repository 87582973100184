<template lang="pug">
  component(:is="'scheme' + scheme", v-bind="$attrs", v-on="$listeners")
</template>

<script>
import SliceEssayImageParallax from './SliceEssayImage--Parallax'
import SliceEssayImageFixed from './SliceEssayImage--Fixed'
export default {
  name: 'SliceEssayImage',
  props: {
    scheme: { type: String, default: '1' }
  },
  components: {
    scheme1: SliceEssayImageParallax,
    scheme2: SliceEssayImageFixed
  }
}
</script>

<style>
</style>
