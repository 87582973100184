<template lang="pug">
  article.home.fixed.overlay.scrollbars-hidden(:class="{'overflow-y-scroll': !locked, 'bg-white': title}")
    template(v-if="doc")
      //- title card
      .absolute.overlay.flex.items-center.justify-center
        //- image
        resp-img.transition-opacity.duration-1000(:image="doc.data.title_card_image", :bg="true", @loaded="runIntro", :class="{'opacity-0': !image}")
        //- title
        rich-text.relative.text-white.font-nils.text-9vw.sm_text-3xl.lg_text-6xl.transition-opacity.duration-1000.whitespace-no-wrap(:field="doc.data.title_card_title", :class="{'opacity-0': !title}")

      //- animating text
      hero-linn-text.relative.z-10
        rich-text.text-navy.hometext(:field="doc.data.animating_text", ref="text", :class="{'pointer-events-none': anim !== false, 'hometext--reveal-all': anim === false}", :data-underlines="underlines")

      //- hover images loader
      img.hidden(src="fail", @error="pinHoverImagesToKeywords")
</template>

<script>
import { mapState } from 'vuex'
import { resizeImgix } from '@/components/RespImg'
import HeroLinnText from '@/components/HeroLinnText'
import store from '@/store'
export default {
  name: 'Home',
  data () {
    return {
      locked: true,
      image: false,
      title: true,
      keywords: null,
      secondaries: null,
      anim: true,
      underlines: false
    }
  },
  computed: {
    ...mapState(['intro', 'canHover']),
    doc () {
      return this.$store.state.docs.find(doc => doc.type === 'home')
    }
  },
  methods: {
    thumb (url, w) {
      w = w * window.devicePixelRatio * 0.75
      return resizeImgix(url, [w])
    },
    async runIntro () {
      if (!this.intro) return
      try {
        this.image = true // reveal image
        await wait(2000)
        // debugger
        this.title = false // hide title
        await wait(800)
        this.animate() // begin animation (over image)
        await wait(200)
        this.image = this.locked = false // hide image, unlock scroll
      } catch (e) {
        console.error(e)
      }
    },
    animate () {
      // 1. animate keywords first
      if (!this.keywords) {
        this.keywords = Array.from(this.$refs.text?.$el.querySelectorAll('a strong'))
      }
      if (this.keywords.length) {
        // reveal keyword
        this.keywords[0].style.opacity = '1'
        // remove keyword
        this.keywords = this.keywords.slice(1)
        // recursive animation...
        this.anim = setTimeout(() => this.animate(), 400)
        return
      }
      // 2. animate secondary words (<em>)
      if (!this.secondaries) {
        this.secondaries = Array.from(this.$refs.text.$el.querySelectorAll('em, p strong'))
        this.underlines = 'ready' // image is hidden...
        // this.anim = setTimeout(() => this.animate(), 800)
        // return
      }
      if (this.secondaries.length) {
        this.secondaries[0].style.opacity = '1'
        this.secondaries = this.secondaries.slice(1)
        this.anim = setTimeout(() => this.animate(), 30)
        return
      }
      // 3. after primary/secondary...
      this.anim = setTimeout(() => this.setToEndState(), 500)
    },
    setToEndState () {
      this.locked = false
      this.title = false
      this.image = false
      // show menu
      this.$store.commit('setUIColor', 'navy')
      // reveal underlines (once)
      this.underlines = 'active'
      // end anim state
      this.anim = false
    },
    pinHoverImagesToKeywords () {
      // add images to DOM
      // required for red-underlining
      const keywords = Array.from(this.$refs.text.$el.querySelectorAll('a'))
      const dpx = window.devicePixelRatio || 1
      // for each hover image...
      this.doc.data.hover_images.forEach(item => {
        // find keyword by mathcing text...
        const keyword = keywords.find(el => el.innerText.toUpperCase() === item.text.toUpperCase())
        if (keyword) {
          const img = document.createElement('img')
          img.setAttribute('src', this.thumb(item.image.url, [item.width_px * dpx * 0.75]))
          img.setAttribute('alt', item.image.alt)
          img.setAttribute('width', item.width_px + 'px')
          img.setAttribute('class', 'absolute z-10 pointer-events-none opacity-0 max-w-none')
          // ensure it's not offscreen :(
          const top = keyword.getBoundingClientRect().top
          if (top < 100) {
            img.style.top = (top * 0.75) + 'px'
          }
          // add!
          keyword.prepend(img) // prepend so can control underline color in CSS (img + strong... red)
        }
      })

      // on keyword hover...
      if (this.canHover) {
        keywords.forEach(el => {
          const img = el.querySelector('img')
          if (img) {
            el.addEventListener('mouseenter', e => {
              // position image based on keyword
              const box = e.target.getBoundingClientRect()
              let top = box.top + this.$el.scrollTop - (img.offsetHeight - box.height) / 2
              top = top < 25 ? 25 : top // ensure not offscreen...
              img.style.top = top + 'px'
              img.style.left = box.left + box.width / 2 + 'px'
            })
          }
        })
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    if (store.state.intro) store.commit('setUIColor', 'transparent')
    next()
  },
  created () {
    if (!this.intro) this.setToEndState()
  },
  beforeRouteLeave (to, from, next) {
    clearTimeout(this.anim)
    store.commit('skipIntro')
    next()
  },
  components: { HeroLinnText }
}

// helpers
export function wait (t, val) {
  return new Promise(resolve => setTimeout(() => resolve(val), t))
}

</script>

<style>
.hometext{
  /* any extra text (commas) */
  color:transparent;

  /* animating elements */
  & em, & i, & strong, & b{
    font-style:normal;
    font-weight: normal;
    color: var(--navy);
    opacity:0;
    transition: opacity 500ms;
  }

  /* prepare underlines for animation */
  &[data-underlines="ready"]{
    & strong, & b, & a{
      /*border-bottom:1px solid transparent;*/
      box-shadow:
          0 calc(-0.125em + 1px) 0 var(--vanilla) inset,
          0 -0.125em 0 transparent inset;
    }
  }

  /* underlines end-state */
  &[data-underlines="active"]{
    /*keywords w/o images: underline blue (default) */
    & strong{
      transition: opacity 500ms, box-shadow 1000ms, border-color 1000ms;
      /*border-bottom:1px solid var(--navy);*/
      box-shadow:
        0 calc(-0.125em + 1px) 0 var(--vanilla) inset, /* 5/48px * 1em  */
        0 -0.125em 0 var(--navy) inset; /* 6/48px * 1em */
    }
    /* keywords with images: underline red*/
    & img + strong {
      transition: opacity 500ms, box-shadow 1000ms, border-color 1000ms;
      /*border-bottom:1px solid var(--red);*/
      box-shadow:
        0 calc(-0.125em + 1px) 0 var(--vanilla) inset,
        0 -0.125em 0 var(--red) inset;
    }
  }

  /* end state */
  &.hometext--reveal-all{
    transition: color 500ms;
    color:var(--navy);
    & *:not(img) {
      opacity:1;
    }
  }
}

@media (hover:hover) {
  .hometext a:hover img {
    opacity:1;
  }
}
</style>
