<template lang="pug">
  #app.text-base.font-nils(:class="{'h-screen overflow-hidden': scrollLockPoint > -1}")
    app-header
    transition(name="page")
      //- use meta.key to manage when page components change (passages/info), fallback to path
      router-view(:key="$route.meta.key || $route.path")
</template>

<script>
import { mapState } from 'vuex'
import '@/style/_main.css'
export default {
  name: 'App',
  data () {
    return {
      scrollLockPoint: -1
    }
  },
  computed: {
    ...mapState(['scrollLocked', 'winW', 'winH'])
  },
  watch: {
    scrollLocked (locked) {
      // lock scroll
      if (locked) {
        // restricts height to screen via $el
        this.scrollLockPoint = window.pageYOffset
        // update inner scorll of $el to simulate original position
        this.$nextTick(() => this.$el.scroll(0, this.scrollLockPoint))
      // unlock
      } else {
        this.scrollLockPoint = -1 // unlocks
        this.$nextTick(() => {
          window.scrollTo(0, this.scrollLockPoint) // jump to saved pos
        })
      }
    }
  },
  methods: {
    onResize () {
      const winW = window.innerWidth
      const winH = window.innerHeight
      // if win width changes... or winH changes meaningfully (ignore ios height change on scroll)
      if (winW !== this.winW || winH <= this.winH * 0.8 || winH >= this.winH * 1.2) {
        requestAnimationFrame(() => {
          this.$store.commit('setWin', { w: winW, h: winH })
        })
      }
    }
  },
  created () {
    this.$store.dispatch('getDocs')
  },
  mounted () {
    window.addEventListener('resize', this.onResize)
  },
  destroyed () {
    window.addEventListener('resize', this.onResize)
  }
}
</script>

<style>
/* scroll snap
html,body{
  /* makes vue-scrollto janky :(
  scroll-snap-type:y proximity;
  scroll-behavior: smooth;
}
.scroll-snap-start{
  scroll-snap-align:start;
}
*/

#app{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page-leave-active{
  transition: opacity 1000ms;
}
.page-enter-active{
  transition: opacity 1000ms 500ms;
}
.page-enter,.page-leave-to{
  opacity:0;
}
</style>
