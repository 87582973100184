<template lang="pug">
  figure.slice-slide-image-cover.absolute.overlay(:style="{background: slice.primary.bg_color}")
    //- inner frame explicity height so ios bottom bar doesn't clip it, like with .h-screen
    .absolute.top-0.left-0.w-full.flex.py-8.md_py-20.lg_p-0(:style="{height: winH + 'px'}")
      .relative.w-full
        slide-media(:slice="slice", :bg="true", v-bind="$attrs", fit="object-contain object-center lg_object-cover")
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'SliceSlideImageCover',
  props: {
    slice: { type: Object, default: () => ({ primary: {} }) }
  },
  computed: {
    ...mapState(['winH'])
  }
}
</script>

<style>
</style>
