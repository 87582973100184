<template lang="pug">
  app-cursor(@mousemove="onMousemove", @click.native="$emit(cursor)")
    .w-4.h-4.relative.text-white.select-none
      //- cursor text
      .absolute.overlay.font-louize.text-xl.tracking-wide.whitespace-no-wrap.flex.justify-center.items-center(v-show="text && cursor === 'text'", :style="{color: slice.primary.cursor_text_color}") {{ text }}
      //- bottom arrow
      .absolute.overlay.flex.items-center.justify-center(v-show="cursor === 'bottom'")
        div(style="width:113px")
          svg-long-arrow.block.transform.rotate-90
      //- left arrow
      .absolute.overlay.flex.items-center.justify-center(v-show="cursor === 'left'")
        div(style="width:113px")
          svg-long-arrow.block.transform.-scale-x-100
      //- right arrow
      .absolute.overlay.flex.items-center.justify-center(v-show="cursor === 'right'")
        div(style="width:113px")
          svg-long-arrow.block
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'SlideshowCursor',
  props: {
    slice: { type: Object, default: () => ({ primary: { cursor_text: [] } }) },
    hideLeft: { type: Boolean, default: false }
  },
  data () {
    return {
      cursor: 'none',
      lastMouse: {}
    }
  },
  computed: {
    ...mapState(['winH', 'winW']),
    text () {
      const txt = this.slice.primary.cursor_text || ''
      return txt.length ? this.$prismic.asText(txt) : false
    }
  },
  methods: {
    onMousemove (e) {
      this.lastMouse = e
      // bottom
      const isBottom = e.offsetY >= this.winH * 0.7
      if (isBottom) {
        this.cursor = 'bottom'
        return
      }
      // text
      if (this.text && e.offsetX > 0.2 * this.winW && e.offsetX <= this.winW * 0.9) {
        this.cursor = 'text'
        return
      }
      // left / right arrows
      this.cursor = e.offsetX < this.winW * 0.5 && !this.hideLeft ? 'left' : 'right'
    }
  },
  watch: {
    text () {
      this.onMousemove(this.lastMouse)
    }
  }
}
</script>

<style>
</style>
