<template lang="pug">
  .slide-media.select-none
    //- ("moving image" video)
    template(v-if="movingImageDoc")
      //- poster
      resp-img(:image="movingImageDoc.data.poster", :bg="true", :fit="fit")
      //- video (appears above poster b/c absolute)
      video.absolute.overlay(ref="video", v-if="videoUrl", :src="videoUrl", loop, muted, playsinline, :class="[fit]", @canplay="play", @play="onPlay", @pause="onPaused", :key="videoUrl")

    //- (image)
    template(v-else)
      resp-img(v-bind="$attrs", :image="slice.primary.media", :fit="fit")
</template>

<script>
export default {
  name: 'SlideMedia',
  props: {
    slice: { type: Object, default: () => ({ primary: {} }) },
    isActive: { type: Boolean, default: false }, // inherited from SlicesSlideshow.vue
    fit: { type: String, default: 'object-cover object-center' }
    // bg: ...inherited from $attrs
  },
  data () {
    return {
      anim: null
    }
  },
  computed: {
    movingImageDoc () {
      return this.$store.state.docs.find(doc => doc.id === this.slice?.primary?.media?.id)
    },
    videoUrl () {
      const key = this.$store.getters.is('lg') ? 'video' : 'video_mobile'
      return this.movingImageDoc?.data[key].url || this.movingImageDoc?.data.video.url
    }
  },
  methods: {
    play (delay = 0) {
      // sanitize delay in case $event is passed in...
      delay = isNaN(delay) ? 0 : delay
      clearTimeout(this.anim)
      if (!this.isActive) return
      this.anim = setTimeout(() => {
        return this.$refs.video?.play()
      }, delay)
    },
    pause () {
      clearTimeout(this.anim)
      return this.$refs.video?.pause()
    },
    onPlay () {
      window.removeEventListener('focus', this.play)
      window.addEventListener('blur', this.pause)
    },
    onPaused () {
      window.removeEventListener('blur', this.pause)
      window.addEventListener('focus', this.play)
    }
  },
  watch: {
    isActive (active) {
      return active && this.$refs.video ? this.play(0) : this.pause()
    }
  },
  mounted () {
    this.play(0)
  },
  destroyed () {
    window.removeEventListener('blur', this.pause)
    window.removeEventListener('focus', this.play)
  }
}
</script>

<style>
</style>
