<template lang="pug">
  <svg width="113" height="16" viewBox="0 0 113 16" xmlns="http://www.w3.org/2000/svg">
    <path d="M105.095 0H103.213C104.869 2.77228 106.977 5.22772 109.462 7.20792H0V8.79208H109.462C106.977 10.7723 104.945 13.2277 103.213 16H105.095C107.053 12.7525 109.838 9.9802 113 8C109.913 5.94059 106.977 3.32673 105.095 0Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'SVG-RightArrow'
}
</script>

<style>
</style>
