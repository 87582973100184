<template lang="pug">
  .vimeo-player.overflow-hidden.transition.duration-500(:class="{'opacity-0': invisible, 'absolute overlay': bgSize}")
    //- frame
    .vimeo-player__frame.overflow-hidden-off(ref="vimeo", :class="{'relative': !bgSize, 'pb-ar-16x9': !bgSize && !loaded, 'absolute': bgSize, 'pointer-events-none': !clickable}", :style="!bgSize && ratio && {paddingBottom: `calc(100% * ${ratio})`}")
      //- <iframe> video will be injected
    resp-img(v-if="poster && !hasImagery", :image="poster", :bg="true")
</template>

<script>
import Player from '@vimeo/player'
export default {
  name: 'VimeoPlayer',
  props: {
    vimeoURL: { type: String, default: undefined },
    bgSize: { type: String, default: undefined },
    clip: { type: Array, default: () => [] },
    controls: { type: Boolean, default: false },
    visible: { type: Boolean, default: true },
    playBtn: { type: Boolean, default: false },
    loop: { type: Boolean, default: true },
    poster: { type: Object, default: undefined },
    clickable: { type: Boolean, default: false },
    observe: { type: Boolean, default: false },
    autoplay: { type: Boolean, default: true }
  },
  data () {
    return {
      player: null,
      loaded: false,
      hasImagery: false,
      invisible: true,
      elW: 0,
      elH: 0,
      videoW: 0,
      videoH: 0,
      iframe: null,
      playing: null, // null while starting in looped-clip state
      muted: true,
      duration: 0,
      userPlayed: false
    }
  },
  computed: {
    elRatio () {
      return this.elH / this.elW
    },
    ratio () {
      return this.videoH / this.videoW
    },
    clipEnabled () {
      return this.clip[0] !== undefined && this.clip[1] > this.clip[0]
    },
    clipMode () {
      return this.playing === null && this.clipEnabled
    }
  },
  watch: {
    visible (vis) {
      if (vis) {
        this.$nextTick(() => this.resizeIframe())
        this.play()
      } else {
        this.pause()
      }
    }
  },
  mounted () {
    this.measure()
    this.init()
    window.addEventListener('resize', this.onNewWindowWidth)
    // this.$store.state.bus.$on('newWindowWidth', this.onNewWindowWidth)
  },
  destroyed () {
    window.removeEventListener('resize', this.onNewWindowWidth)
    // this.$store.state.bus.$off('newWindowWidth', this.onNewWindowWidth)
  },
  methods: {
    init () {
      if (!this.vimeoURL) { return console.warn('No vimeo URL') }
      const options = {
        url: this.vimeoURL,
        background: true,
        loop: this.loop
      }
      if (this.bgSize === 'cover') {
        options.maxheight = this.elH * 0.8
      } else {
        options.maxwidth = this.elW
      }
      // create player
      this.player = new Player(this.$refs.vimeo, options)
      // events
      this.player.on('loaded', async () => {
        try {
          // await this.startAtClip()
          this.loaded = true
          this.$emit('loaded')

          // resize and reveal
          this.resizeIframe()
          // this.duration = await this.player.getDuration()
          // this.autoPlayPauseViewport()
        } catch (e) {
          console.error(e)
        }
      })
      this.player.on('play', () => { this.playing = true; this.$emit('playing', this.playing) })
      this.player.on('pause', () => { this.playing = false; this.$emit('playing', this.playing) })
      // this.player.on('timeupdate', this.onTimeUpdate)

      // use first @timeupdate to cancel autoplay if needed
      this.player.on('timeupdate', this.onFirstPlay)
      this.player.on('ended', () => this.$emit('ended'))
    },

    startAtClip () {
      // jump to clip start (if set)
      if (!this.clipEnabled) { return Promise.resolve() }
      return this.player.setCurrentTime(this.clip[0])
    },
    async onFirstPlay () {
      /**
       * Function to emit when video has visible imagery
       * - bind to 'timeupdate' because 'play' event fires inconsistently...
      **/
      // this.hasImagery = true
      //
      // remove handler
      // alert('on' + await this.player.getPaused())
      this.player.off('timeupdate', this.onFirstPlay)

      if (!this.autoplay && !this.userPlayed) {
        console.log('cancelled autoplay')
        this.pause()
      }

      // this.$emit('firstplay')

      // skip if vimeo not visible yet
      // if (!this.visible) { return }
      // emit event (functions as "isLoaded")
      // this.$emit('firstplay')
    },
    onTimeUpdate (time) {
      // TODO - maybe don't update if in clip mode still ?
      if (this.playing !== null) {
        this.$emit('progress', time.percent * 100)
      }
      // if in clip mode - loop back to clip start on clip end
      if (this.clipEnabled && this.playing === null) {
        const end = this.clip[1]
        return end && time.seconds >= end && this.player.setCurrentTime(this.clip[0])
      }
    },

    play () {
      return this.player.getPaused().then(paused => paused && this.player.play())
    },
    pause () {
      return this.player.getPaused().then(paused => !paused && this.player.pause())
    },

    async playBtnClick (unmute = false) {
      try {
        if (this.playing) {
          return this.pause()
        }
        this.userPlayed = true
        await this.play()
        // unmute? (must wait for play...)
        if (unmute) this.unmute()
        return true
      } catch (e) {
        console.error(e)
      }
    },

    /* Progress */
    async changeProgress (pct) {
      try {
        await this.player.setCurrentTime(Number(pct) * this.duration)
        return true
      } catch (err) {
        console.error(err)
      }
    },

    /* Mute / Unmute */
    mute () {
      return this.player.setMuted(true).then((mtd) => {
        this.muted = mtd
        this.$emit('mutechange', mtd)
      })
    },
    unmute () {
      // WARNING - ios low battery prevents
      return this.player.setMuted(false).then((mtd) => {
        this.muted = mtd
        this.$emit('mutechange', mtd)
      }).catch(e => console.error('Unmute error:', e))
    },
    toggleMute () {
      return this.muted ? this.unmute() : this.mute()
    },

    /* Fullscreen */
    async fullscreen () {
      try {
        await this.player.requestFullscreen()
        // TODO - maybe don't play for iOS since it seems to keep player in bg
        this.playBtnClick(true)
      } catch (e) {
        console.error(e)
      }
    },

    /* Resizing */
    onNewWindowWidth () {
      // if (this.bgSize === 'cover') return
      this.measure()
      this.resizeIframe()
    },
    measure () {
      this.elW = this.$el.offsetWidth
      this.elH = this.$el.offsetHeight
    },
    resizeIframe () {
      this.measure()
      this.iframe = this.iframe || this.$el.querySelector('iframe')
      const iframe = this.iframe
      const size = this.bgSize
      if (!iframe) { return console.warn('No iframe!') }
      // get aspect ratio
      if (!this.videoW) {
        this.videoW = iframe.getAttribute('width')
        this.videoH = iframe.getAttribute('height')
      }
      // resize (if background)
      if (size) {
        const vidIsWider = this.ratio <= this.elRatio // video wider than frame
        const scaleByHt = (size === 'cover' && vidIsWider) || (size === 'contain' && !vidIsWider)
        // apply
        if (scaleByHt) {
          const w = this.elH * this.videoW / this.videoH
          iframe.setAttribute('height', this.elH)
          iframe.setAttribute('width', parseInt(w))
        } else {
          const h = this.elW * this.videoH / this.videoW
          iframe.setAttribute('height', parseInt(h))
          iframe.setAttribute('width', this.elW)
        }
      }
      this.invisible = false
    },

    /* Observer for auto play/pause */
    autoPlayPauseViewport () {
      if (!this.observe) { return }
      const observer = new IntersectionObserver((entries) => {
        return entries[0].isIntersecting ? this.play() : this.pause()
      })
      observer.observe(this.$el)
    }
  }
}
</script>

<style>
.vimeo-player__frame{
  &.absolute{
    top:50%; left:50%;
    transform: translate(-50%, -50%);
  }
  & iframe {
    /*transition: filter 600ms;*/
    /*pointer-events: none !important;*/
  }
  /*&.vimeo-player__frame--blurred iframe{
    filter:blur(6px);
  }*/
  &.relative iframe{
    position: absolute;
    top:0; left:0;
    width:100% !important;
    height:100% !important;
  }
}
</style>
