import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const vue = new Vue()

export default new Vuex.Store({
  state: {
    intro: true,
    docs: [],
    winH: window.innerHeight,
    winW: window.innerWidth,
    canHover: window.matchMedia('(hover:hover)').matches,
    scrollLocked: false, // disabled
    uiColor: 'white'
  },
  getters: {
    main (state) {
      return state.docs.find(doc => doc.type === 'settings')
    },
    // slideshow based pages...
    passages (state) {
      // Garden is first
      return state.docs.filter(doc => doc.type === 'passage').sort((a, b) => a.uid === 'garden' ? -1 : 0)
    },
    essays (state) {
      return state.docs.filter(doc => doc.type === 'essay')
    },
    is: (state) => (key) => {
      return key === 'portrait' ? state.winH >= state.winW
        : key === 'lg' ? state.winW >= 1024
          : key === 'md' ? state.winW >= 768
            : false
    }
  },
  mutations: {
    setDocs (state, docs) {
      state.docs = docs
    },
    setWin (state, { w, h }) {
      state.winW = w || state.winW
      state.winH = h || state.winH
    },
    lockScroll (state) {
      state.scrollLocked = true
    },
    unlockScroll (state) {
      state.scrollLocked = false
    },
    setUIColor (state, theme = 'white') {
      state.uiColor = theme
    },
    skipIntro (state) {
      state.intro = false
    }
  },
  actions: {
    getDocs ({ commit }) {
      return vue.$prismic.client.query('', { pageSize: 1000 })
        .then(resp => commit('setDocs', resp.results))
        .catch(e => console.error(e))
    }
  }
})
