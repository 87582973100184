<template lang="pug">
  section.slices-slideshow.absolute.overlay.text-white(@mousemove="onMousemove", @touchstart="onTouchstart", @touchmove="onTouchmove", @click="onClick", :style="{touchAction: !canHover ? 'pan-y' : ''}")
    //- slides...
    transition-group(:name="transition", @before-leave="isAnimating = true", @after-enter="isAnimating = false")
      .slices-slideshow__slide.absolute.overlay(v-for="(slice, i) in slices", v-show="current === i", :key="'slice' + i")
        //- (component)
        component(v-if="has(slice) && renderSlice(i)", :is="'slice--' + slice.slice_type", :slice="slice", :index="i", v-on="$listeners", :isActive="current === i", :hoverItem="hoverItem", @next="next")

    //- cursors
    template(v-if="canHover")
      slideshow-cursors.z-10(:slice="sliceActive", @right="next", @left="prev", @bottom="$emit('downArrowClick')", @text="next", :hideLeft="disablePrev")

</template>

<script>
import { mapState } from 'vuex'
import SliceSlideImageCover from '@/slices/SliceSlideImageCover'
import SliceSlideImagePortrait from '@/slices/SliceSlideImagePortrait'
import SliceSlideQuote from '@/slices/SliceSlideQuote'
import SliceTitleCard from '@/slices/SliceTitleCard'
import SliceSlideEssay from '@/slices/SliceSlideEssay'
import SliceSlideVimeo from '@/slices/SliceSlideVimeo'
import throttle from 'lodash/throttle'
export default {
  name: 'SlicesSlideshow',
  props: {
    slices: { type: Array, default: () => ([]) }
  },
  data () {
    return {
      current: !isNaN(this.$route.params.slide) ? Number(this.$route.params.slide) : 0,
      transition: 'crossfade',
      isAnimating: false,
      hoverItem: 0,
      touch0: null
    }
  },
  computed: {
    ...mapState(['canHover']),

    sliceActive () {
      return this.slices[this.current] ?? {}
    },
    disablePrev () {
      // disable if at beginning
      return this.current === 0 && this.$route.params.passage === 'garden' // this.isAnimating
    }
  },
  watch: {
    current (entering, leaving) {
      // get transition from slice, depending on direction of movement
      const isReverse = entering < leaving
      const source = isReverse ? entering : leaving
      const name = this.slices[source].primary.transition_out ?? 'crossfade'
      let trans = isReverse && name !== 'crossfade' ? name + '-reverse' : name
      trans = Math.abs(entering - leaving) > 1 ? 'fade-next-only' : trans // crossfade if jumping from index
      this.transition = trans
      // update route ?
      if (entering !== this.$route.params.slide) {
        // TODO - maybe use sessionStorage since safari reveals cursor on rt change...
        this.$router.replace({ params: { slide: entering } })
      }
    },
    '$route' (to, from) {
      // update slide via route (passage thumbnails)
      if (to.params.slide) {
        this.current = to.params.slide
      }
    }
  },
  methods: {
    has (slice) {
      return this.$options.components['slice--' + slice.slice_type]
    },
    renderSlice (i) {
      return i <= this.current + 2 && i >= this.current - 1
    },
    onMousemove: throttle(function (e) {
      if (this.canHover && this.sliceActive?.slice_type === 'title_slide_interactive') {
        this.hoverItem = Math.floor(e.pageX / (this.$el.offsetWidth / this.sliceActive.items.length))
        return
      }
      this.hoverItem = 0
    }, 50),
    next () {
      // end?
      if (this.current === this.slices.length - 1) return this.$emit('end')
      // ...
      this.current++
    },
    prev () {
      // on safe-passage beginning, prev goes to garden end
      if (this.current === 0 && this.$route.params.passage === 'safe-passage') {
        const garden = this.$store.getters.passages.find(doc => doc.uid === 'garden')
        const gardenTotalSlides = garden?.data.body.length - 1
        return gardenTotalSlides && this.$router.push({ name: 'passage', params: { passage: 'garden', slide: gardenTotalSlides } })
      }
      // ...else next
      this.current--
    },
    onKey: throttle(function (e) {
      if (this.sliceActive?.slice_type === 'essay') return
      switch (e.keyCode) {
        case 39:
          e.preventDefault()
          this.next()
          break
        case 37:
          e.preventDefault()
          this.prev()
      }
    }, 1000),

    // touch actions (swipe, tap)
    onTouchstart (e) {
      // if only one finger...
      if (e.touches.length === 1 && !this.canHover) {
        // save initial touch
        this.touch0 = e.touches[0]
      }
    },
    onTouchmove (e) {
      if (this.touch0 && !this.canHover) {
        // compare current touch to initial touch
        const dX = this.touch0.pageX - e.touches[0].pageX
        const dY = this.touch0.pageY - e.touches[0].pageY
        // if in swipe range...
        if (Math.abs(dY) < 80 && Math.abs(dX) > 80) {
          // clear touch0 so doesn't keep firing in this touch action
          this.touch0 = null
          // next or prev ?
          return dX > 0 ? this.next() : this.prev()
        }
      }
    },
    onClick (e) {
      // next slide if no hover / cursors
      return !this.canHover && this.next()
    }
  },
  mounted () {
    window.addEventListener('keydown', this.onKey)
  },
  destroyed () {
    window.removeEventListener('keydown', this.onKey)
  },
  components: {
    'slice--slide_image_cover': SliceSlideImageCover,
    'slice--slide_image_portrait': SliceSlideImagePortrait,
    'slice--quote': SliceSlideQuote,
    'slice--title_slide_interactive': SliceTitleCard,
    'slice--essay': SliceSlideEssay,
    'slice--vimeo': SliceSlideVimeo
  }
}
</script>

<style>
.cursor-icon-right-arrow{
  cursor: url('/icons/right-arrow.png') 113 16, e-resize;
}

/* CROSSFADE */
.crossfade-enter-active,
.crossfade-leave-active{
  transition:opacity 600ms; /* 1200ms; */
}
.crossfade-enter,.crossfade-leave-to{
  opacity:0;
}

/* SLIDE LEFT FADE IN */
.slide-left-fade-in-leave-active{
  pointer-events: none; /* hide cursors...*/
  z-index:2;
  /* <figure> slides away... */
  & figure {
    transition: transform 600ms ease-out;
  }
  /* ...then slice (bg) fades out after <figure> transform with delay */
  transition: opacity 600ms 800ms;
  /* LEAVE TO */
  &.slide-left-fade-in-leave-to{
    opacity:0;
    & figure{ transform:translate(-201%); }
  }
}
/* entering rules (for <img> delay on diptych slices) */
.slide-left-fade-in-enter-active{
  /* fake property to ensure animation length... */
  /* <figure>slide + bg fade+delay*/
  transition: border-color 0s 1400ms;
  /* ENTER */
  &.slide-left-fade-in-enter{
    border-color: transparent;
  }
}

/* SLIDE LEFT FADE IN (REVERSE) >> */
.slide-left-fade-in-reverse-enter-active{
  pointer-events: none; /* hide cursors...*/
  z-index:2;
  /* slice bg fades in (border-color is just to ensure length for figure) */
  transition: opacity 600ms, border-color 0s 1200ms;
  /* ...<figure> slides in second */
  & figure {
    transition: transform 600ms 600ms ease-out;
  }
}
/* (leaving - ensure it's visible) */
.slide-left-fade-in-reverse-enter{
  opacity:0;
  border-color:transparent;
  & figure{ transform:translate(-201%); }
}
.slide-left-fade-in-reverse-leave-active{
  transition:opacity 0s 600ms;
}
.slide-left-fade-in-reverse-leave-to{
  opacity: 0;
}

/* FADE NEXT ONLY (thumbs) */
.fade-next-only-enter-active{
  transition:opacity 600ms 600ms; /* extra delay so image loads in time...*/
}
.fade-next-only-enter{
  opacity:0;
}
</style>
