<template lang="pug">
  //-
    - BACKGROUND COLORS KEYS !!
    - bg_color_left = base bg color (persists during slide animation)
    - bg_color_right = bg behind image (if visible)
  .slice-slide-image-portrait.absolute.overlay(:data-format="format", :style="{background: slice.primary.bg_color_left}")
    //- (mobile bg color)
    .absolute.overlay.lg_hidden(:style="{background: slice.primary.bg_color_right}", v-if="slice.primary.bg_color_right")

    //- frame
    .w-full.flex(:style="{height: winH + 'px'}")
      //- image left cover
      template(v-if="format === 'image_left_cover'")
        figure.w-full.lg_w-1x2.p-8.md_p-20.lg_p-0.flex
          .relative.w-full
            slide-media(:slice="slice", v-bind="$attrs", :bg="true", fit="object-contain object-center lg_object-cover")
        .lg_w-1x2

      //- image right cover
      template(v-else-if="format === 'image_right_cover'")
        .lg_w-1x2
        figure.w-full.p-8.md_p-20.lg_w-1x2.lg_p-0.flex
          .relative.w-full
            slide-media(:slice="slice", v-bind="$attrs", :bg="true", fit="object-contain object-center lg_object-cover")

      //- image left inset (centered)
      template(v-else-if="format === 'image_left_inset'")
        figure.w-full.p-8.md_p-20.lg_w-1x2.flex(:style="{background: slice.primary.bg_color_right}")
          .relative.w-full
            slide-media(:slice="slice", v-bind="$attrs", :bg="true", fit="object-contain object-center")
        .lg_w-1x2

      //- image right inset (centered)
      template(v-else-if="format === 'image_right_inset'")
        .lg_w-1x2
        figure.w-full.p-8.md_p-20.lg_w-1x2.flex(:style="{background: slice.primary.bg_color_right}")
          .relative.w-full
            slide-media(:slice="slice", v-bind="$attrs", :bg="true", fit="object-contain object-center")

      //- image left fit left
      template(v-else-if="format === 'image_left_fit_left'")
        figure.w-full.p-8.md_p-20.lg_w-1x2.lg_p-0.flex(:style="{background: slice.primary.bg_color_right}")
          .relative.w-full
            slide-media(:slice="slice", v-bind="$attrs", :bg="true", fit="object-contain object-left")
        .lg_w-1x2

      //- image right fit right
      template(v-else-if="format === 'image_right_fit_right'")
        .lg_w-1x2
        figure.w-full.p-8.md_p-20.lg_w-1x2.lg_p-0.flex(:style="{background: slice.primary.bg_color_right}")
          .relative.w-full
            slide-media(:slice="slice", v-bind="$attrs", :bg="true", fit="object-contain object-right")

      //- centered with margins (default)
      template(v-else)
        figure.w-full.p-8.md_p-20.flex
          .relative.w-full
            slide-media(:slice="slice", v-bind="$attrs", :bg="true", fit="object-center object-contain")
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'SliceSlideImagePortrait',
  props: {
    slice: { type: Object, default: () => ({ primary: {} }) }
  },
  computed: {
    ...mapState(['winH']),
    format () {
      return this.slice?.slice_label || ''
    }
  }
}
</script>

<style>
@media (min-width:1024px) {
  /* on slide-left-fade-in transition, img fades in late */
  .slice-slide-image-portrait{
    & img{
      transition: opacity 600ms;
    }
  }

  /* hidden while slide animates in */
  .slide-left-fade-in-enter-active .slice-slide-image-portrait img{
    opacity: 0;
  }
}
</style>
