<template lang="pug">
  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11.1901 0.0365087C11.047 -0.0286864 10.8753 -0.00540242 10.756 0.0970471L5.41378 4.60482H0.405438C0.181254 4.60482 0 4.78178 0 5.00065V10.9288C0 11.1476 0.181254 11.3246 0.405438 11.3246H5.33747L10.756 15.9022C10.8323 15.9674 10.9277 16 11.0231 16C11.0804 16 11.1376 15.986 11.1901 15.9627C11.3332 15.8975 11.4286 15.7578 11.4286 15.6042V0.395082C11.4286 0.241408 11.3379 0.101704 11.1901 0.0365087Z" fill="currentColor"/>
  <path d="M17.9381 16C17.8484 16 17.7635 15.9719 17.688 15.9109C17.5135 15.7748 17.4899 15.5215 17.6267 15.3479C19.3064 13.2698 20.2264 10.6615 20.2264 7.99697C20.2264 5.33242 19.3017 2.72417 17.6267 0.64601C17.4899 0.472439 17.5135 0.223811 17.688 0.0877687C17.8626 -0.0482734 18.1127 -0.0248179 18.2495 0.148753C20.0424 2.36765 21.0285 5.15416 21.0285 7.99697C21.0285 10.8398 20.0424 13.631 18.2495 15.8452C18.1693 15.9531 18.0513 16 17.9381 16ZM15.8904 13.9688C15.8055 13.9688 15.7253 13.9453 15.6545 13.889C15.4752 13.7577 15.4375 13.509 15.5696 13.3308C16.7208 11.7733 17.3342 9.9344 17.3342 8.00166C17.3342 6.06893 16.7208 4.23002 15.5696 2.67257C15.4375 2.49431 15.4752 2.24568 15.6545 2.11433C15.8338 1.98298 16.0838 2.0205 16.216 2.19877C17.471 3.89226 18.1363 5.90005 18.1363 8.00166C18.1363 10.1033 17.471 12.1111 16.216 13.8046C16.1357 13.9125 16.0131 13.9688 15.8904 13.9688ZM13.6587 11.7499C13.5832 11.7499 13.5077 11.7264 13.4369 11.6842C13.2529 11.5622 13.201 11.3183 13.3237 11.1306C14.5787 9.23073 14.5787 6.77729 13.3237 4.87739C13.201 4.69444 13.2529 4.44581 13.4369 4.32384C13.6209 4.20187 13.871 4.25347 13.9937 4.43642C15.4233 6.60371 15.4233 9.39961 13.9937 11.5669C13.9182 11.6842 13.7908 11.7499 13.6587 11.7499Z" :fill="muted ? 'transparent' : 'currentColor'"/>
  </svg>

</template>

<script>
export default {
  name: 'SVG-Audio',
  props: {
    muted: { type: Boolean, default: false }
  }
}
</script>

<style>
</style>
