<template lang="pug">
  .essay-body(v-if="doc")
    rich-text(:field="doc.data.body[0].items[0].text")
</template>

<script>
export default {
  name: 'EssayBody',
  props: {
    doc: { type: Object, default: undefined }
  }
}
</script>

<style>
</style>
