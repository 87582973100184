<template lang="pug">
  //- fill frame, click outside bg layer to close
  .essay-lightbox.fixed.overlay.transform.transition-transform.duration-1000.flex.cursor-pointer(:class="{'translate-x-full': !visible, 'pointer-events-none': !visible}", @click="close")
    //- gray bg layer
    .bg-white.ml-auto.w-full.lg_w-11x12.relative.cursor-default(@click.stop, :class="{'bg-gray-100': scheme === '2'}")
      transition-group(name="crossfade")
        //- slides...
        .absolute.overlay(v-for="(slice, i) in slices", v-if="renderSlice(i)", v-show="current === i", :key="'es' + i")

          //- image/s slide
          template(v-if="slice.slice_type === 'image_s'")
            .absolute.overlay.flex.flex-col.lg_flex-row.p-6.pb-20.md_p-10.md_pb-20.lg_p-0(:class="[justify(slice)]")
              //- images...
              figure.p-6.md_p-10.lg_p-20.flex.relative.w-full.flex-1.lg_flex-none(v-for="item in slice.items", :class="[itemWidth(item, slice.items.length)]")
                .relative.w-full
                  resp-img(:bg="true", :image="item.media", fit="object-contain object-center")
                //- (caption lg)
                figcaption.hidden.lg_block.absolute.bottom-0.left-0.pb-6.w-full.px-20
                  rich-text.font-louize.text-sm.text-center(:field="item.caption")
              //- (mbl captions as drawer)
              small.absolute.bottom-0.left-0.w-full.bg-white.px-8.pb-24.font-louize.text-sm.lg_hidden.transition.duration-500.transform(:class="{'translate-y-full': !captionsMobile}")
                rich-text.mt-8.mb-2(v-for="(item, i) in slice.items", :field="item.caption", :key="i + $prismic.asText(item.caption)")

          //- quote slide
          template(v-else-if="slice.slice_type === 'quote_static'")
            .absolute.overlay.flex.items-center.justify-center.text-red.font-nils.text-lg.md_text-3xl.w-full.px-20
              rich-text.max-w-lg.mx-auto.w-full.text-center(:field="slice.primary.quote")

      //- next/prev cursor arrows
      slideshow-cursor-arrows(color="black", :disableLeft="current === 0", :hideLeft="current < 1", @prev="prev", @next="next")

      //- (mbl captions btn)
      button.absolute.bottom-0.left-0.px-8.py-6.lg_hidden.transition-opacity.duration-1000(@click="captionsMobile = !captionsMobile", :class="{'opacity-0 pointer-events-none': !visible || !activeCaptions, 'delay-500': visible && activeCaptions}") Caption
</template>

<script>
export default {
  name: 'EssaySlideshow',
  props: {
    slices: { type: Array, default: () => ([]) },
    visible: { type: Boolean, default: false },
    scheme: { type: String, default: '1' }
  },
  data () {
    return {
      current: 0,
      captionsMobile: false
    }
  },
  computed: {
    activeCaptions () {
      return this.slices[this.current]?.items.filter(item => this.$prismic.asText(item.caption).length).length
    }
  },
  methods: {
    renderSlice (i) {
      return i <= this.current + 2 && i >= this.current - 2
    },
    jumpTo ({ url, index }) {
      if (!isNaN(index)) {
        this.current = index
        return
      }
      // jump to index based on url
      const i = this.slices.findIndex(slice => slice.items?.find(item => item.media?.url === url))
      if (i > -1) {
        this.current = i
        return
      }
      this.current = 0
    },
    close () {
      this.$emit('close')
    },
    itemWidth (item, totalItems) {
      const isPrt = item => item.media?.height > item.media?.width
      return totalItems < 2 ? 'lg_w-13x16'
        : isPrt(item) ? 'lg_w-7x16'
          : 'lg_w-9x16'
    },
    justify (slice) {
      const allItemsPortrait = !slice.items.find(item => item.media.width && item.media.width >= item.media.height)
      // both portrait images ? space evenly
      return slice.items.length >= 2 && allItemsPortrait ? 'justify-center' // "1 col" gap via padding
      // 2 images ? space between
        : slice.items.length >= 2 ? 'justify-between'
        // default: centered (1 image)
          : 'justify-center'
    },
    next () {
      if (this.current === this.slices.length - 1) return this.close()
      return this.current < this.slices.length - 1 && this.current++
    },
    prev () {
      return this.current > 0 && this.current--
    },
    onKey (e) {
      return e.keyCode === 27 ? this.close() // ESC
        : e.keyCode === 37 ? this.prev() // LEFT ARROW
          : e.keyCode === 39 ? this.next() // RIGHT ARROW
            : null
    }
  },
  watch: {
    visible (vis) {
      // on visible...
      if (vis) return window.addEventListener('keydown', this.onKey)
      // on hidden...
      window.removeEventListener('keydown', this.onKey)
      this.current = -1
    }
  },
  components: {}
}
</script>

<style>
/*.essayslide-leave-active{
  transition: opacity 1000ms;
}
.essayslide-enter-active{
  transition: opacity 1200ms 200ms;
}
.essayslide-enter,.essayslide-leave-to{
  opacity:0;
}*/
</style>
