<template lang="pug">
  //- fills parent for mousearea
  .app-cursor.absolute.overlay.flex.items-start.justify-start(v-if="canHover", @mouseenter="visible = true", @mouseleave="visible = false", @mousemove="onMousemove", style="cursor:none")
    //- text follows cursor
    .pointer-events-none.transform(ref="text", :class="{'opacity-0': !visible || (!x || !y)}", :style="{'--transform-translate-y': y + 'px', '--transform-translate-x': x + 'px'}")
      slot
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AppCursor',
  // props: {
  //   color: { type: String, default: 'white' }
  // },
  data () {
    return {
      visible: false,
      x: 0,
      y: 0,
      afterMouse: null
    }
  },
  computed: {
    ...mapState(['canHover'])
  },
  methods: {
    onMousemove (e) {
      // clearTimeout(this.afterMouse)
      this.$emit('mousemove', e)
      // animate
      requestAnimationFrame(() => {
        this.x = e.offsetX - (this.$refs.text.offsetWidth * 0.5)
        this.y = e.offsetY - (this.$refs.text.offsetHeight * 0.5)
      })
      // after mouse, hide
      // this.afterMouse = setTimeout(() => {
      //   this.visible = false
      // }, 2000)
    }
  }
}
</script>

<style>
</style>
