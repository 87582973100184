<template lang="pug">
  header#app-header.font-messina(role="banner")
    //- bottom right buttons (menu / audio)
    footer-links.fixed.z-30.bottom-0.right-0.px-3.flex.items-center.text-base.transition-color.duration-700(:class="'text-' + uiColor")
      button.p-3.-mb-3.focus_outline-none(@click="menu = !menu") Menu
      button.p-3.-mb-3.ml-6(@click="onAudioBtnClick", v-show="audioEnabled || $route.name === 'film'", :class="{'invisible': $route.name === 'film'}")
        svg-audio(:muted="paused")

    //- menu overlay
    nav.fixed.z-40.overlay.overflow-hidden.cursor-pointer.text-navy.transition-opacity.duration-0(role="navigation", v-if="main", :class="{'pointer-events-none opacity-0 delay-700': !menu}", @click="menu = false")
      //- sliding panel
      .absolute.top-0.right-0.w-full.sm_h-full.sm_max-w-menu.bg-vanilla.transition-transform.duration-500.sm_duration-700.transform.cursor-default.overflow-y-scroll.flex(:class="{'-translate-y-full sm_translate-y-0 sm_translate-x-full': !menu}", @click.stop)
        //- landscape menu
        .hidden.sm_flex.flex-col.w-full.pl-16.pt-20.pr-10
          //- main
          ul.flex-1.text-xl
            li(v-for="row in main.data.menu")
              app-link.btn-theme-underline-red(v-if="row.link_text", :field="row.link") {{ row.link_text }}
              span(v-else, v-html="'&nbsp;'")
          //- footer
          footer-links.pt-16.flex.w-full.items-center.justify-between.text-base
            ul.-m-4
              li.inline-block.p-4(v-for="row in main.data.menu_footer_links")
                app-link.btn-theme-underline-red(:field="row.link") {{ row.link_text }}
            .block.-m-4.p-4.tracking-wider
              a.btn-theme-underline-red(href="https://maisondesprit.com", target="_blank") EMH

        //- mobile menu
        .sm_hidden.w-full.py-10.px-8.text-xl
          ul.leading-snug
            li.mb-4(v-for="row in main.data.menu_mobile")
              app-link(v-if="row.link_text", :field="row.link") {{ row.link_text }}
              span(v-else, v-html="'&nbsp;'")
          small.mt-16.flex.text-base.w-full.justify-between
            a.tracking-wider(href="https://maisondesprit.com", target="_blank") EMH
            button.p-3.-m-3(@click="menu = false") Close

    //- global audio track
    .fixed.top-0.left-0.z-50(ref="audio", v-show="$route.hash === '#debug' && $route.name === 'passage'")
      audio(v-if="loadAudio", src="/audio/3_poems.mp3", @playing="paused = false", @pause="paused = true", controls, @loadedmetadata="onAudioLoaded")
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'AppHeader',
  data () {
    return {
      menu: false,
      paused: true,
      autoplayEnabled: true,
      loadAudio: this.$route.name !== 'playlist'
    }
  },
  computed: {
    ...mapState(['uiColor']),
    ...mapGetters(['main']),

    audioEnabled () {
      return this.$route.name === 'passage'
    }
  },
  watch: {
    '$route' (to, from) {
      this.menu = false
      this.loadAudio = to.name !== 'playlist'

      // autoplay audio on Garden
      if (to.params.passage === 'garden') {
        this.autoPlay()
      }
    },
    audioEnabled (enabled) {
      if (!enabled) {
        this.pauseAudio()
      }
    }
  },
  methods: {
    audioEl () {
      return this.$refs.audio.children && this.$refs.audio.children[0]
    },
    onAudioBtnClick () {
      return this.paused ? this.playAudio() : this.pauseAudio(true)
    },
    playAudio () {
      if (this.paused) {
        this.autoplayEnabled = true
        return this.audioEl()?.play()
      }
    },
    pauseAudio (disableAutoplay) {
      if (!this.paused) {
        if (disableAutoplay) {
          this.autoplayEnabled = false
        }
        return this.audioEl()?.pause()
      }
    },
    autoPlay () {
      if (!this.autoplayEnabled) return
      if (!this.audioEl()) return console.warn('Audio not loaded yet')
      try {
        // on garden slide 0? start from beginning
        const isGardenFirstSlide = this.$route.params.passage === 'garden' && this.$route.params.slide === 0
        if (isGardenFirstSlide) {
          this.audioEl().currentTime = 0
        }
        // assume it's paused?
        this.audioEl().play()
      } catch (e) {
        console.error('Audio autoplay error:', e)
      }
    },
    onAudioLoaded () {
      if (this.$route.params.passage === 'garden') {
        this.autoPlay()
      }
    }
  }
}
</script>

<style>
</style>
