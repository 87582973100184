<template lang="pug">
  .film.fixed.overlay.bg-vanilla.flex.p-48
    .relative.w-full(v-if="doc")
      vimeo-player(:vimeoURL="doc.data.vimeo_url", bgSize="contain")
</template>

<script>
import store from '@/store'
export default {
  name: 'Film',
  computed: {
    doc () {
      return this.$store.state.docs.find(doc => doc.type === 'film')
    }
  },
  beforeRouteEnter (to, from, next) {
    store.commit('setUIColor', 'navy')
    next()
  }
}
</script>

<style>
</style>
