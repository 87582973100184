<template lang="pug">
  nav.slideshow-cursor-arrows
    //- right arrow (conditional width)
    button.absolute.w-1x2.top-0.right-0.h-full.focus_outline-none(aria-label="Next Slide", :disabled="disableRight", @click="$emit('next')", v-show="!hideRight")
      app-cursor(v-bind="$attrs")
        svg-long-arrow.block
    //- left arrow
    button.absolute.w-1x2.top-0.left-0.h-full.focus_outline-none(aria-label="Previous Slide", :disabled="disableLeft", @click="$emit('prev')", v-show="!hideLeft")
      app-cursor(v-bind="$attrs")
        svg-long-arrow.block.transform.-scale-x-100
</template>

<script>
export default {
  name: 'SlideshowCursorArrows',
  props: {
    hideLeft: { type: Boolean, default: false },
    hideRight: { type: Boolean, default: false },
    disableLeft: { type: Boolean, default: false },
    disableRight: { type: Boolean, default: false }
    // fullRight: { type: Boolean, default: false }
  }
}
</script>

<style>
</style>
