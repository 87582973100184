<template lang="pug">
  .footer-links.py-6.font-messina.leading-tight
    slot
</template>

<script>
export default {
  name: 'FooterLinks'
}
</script>

<style>
</style>
