export default function (doc) {
  // Return the path depending on Prismic Document's type
  const url = {
    passage: '/' + doc.uid,
    essays: '/writing',
    essay: '/writing/' + doc.uid,
    playlist: '/listen/' + doc.uid,
    info: '/info/' + doc.uid,
    objects: '/objects',
    olfactory: '/olfactory',
    film: '/films',
    settings: '/'
  }
  return url[doc.type] || '/'
}
