<template lang="pug">
  //- close essay btn
  button.close-btn-x.p-10.lg_p-20(aria-label="Close", @click="$emit('click')")
    svg-x.block.h-8.w-8.lg_h-12.lg_w-12
</template>

<script>
export default {
  name: 'CloseBtnX'
}
</script>

<style>
</style>
